/* Variables import needs to be on top! */
@import './helper/variables';
@import '~bootstrap';
@import '~@fortawesome/fontawesome-free/css/all.css';

@import './layout/header';
@import './layout/footer';

@import './components/question';
@import './components/answer';
@import './components/login';

.jumbotron {
    margin-bottom: 0px;
}
.jumbotron-img {
    background: rgb(237,116,88);
    background: linear-gradient(302deg, rgba(237,116,88,1) 16%, rgba(51,61,81,1) 97%);
    color: #fff;
}

.home-banner {
    background-image: url("../images/home-banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bjj-banner {
    min-height: 40em;
    background-image: url("../images/bjj-banner.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.grappling-banner {
    min-height: 40em;
    background-image: url("../images/grappling-banner.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.mma-banner {
    min-height: 40em;
    background-image: url("../images/mma-banner.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
