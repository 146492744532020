.q-container {
  border-top-right-radius: .25rem;
  border-top-left-radius: .25rem;
  background-color: #efefee;
}

.q-container-show {
  border-top-right-radius: .25rem;
  border-top-left-radius: .25rem;
  background-color: #ED7458 ;
}

.q-container img, .q-container-show img {
  border: 2px solid #fff;
  border-radius: 50%;
}

.q-display {
  background: #fff;
  border-radius: .25rem;
}
.q-title-show {
  text-transform: uppercase;
  font-size: 1.3rem;
  color: #fff;
}
.q-title {
  text-transform: uppercase;
  color: #444;
}

.q-title:hover {
  color: #2B2B2B;
}

.q-title h2 {
  font-size: 1.3rem;
}

.q-display-response {
  background: #333D51;
  color: #fff;
}

.btn-question {
  color: #FFFFFF;
  background-color: #ED7458;
  border-color: #D45B3F;
}

.btn-question:hover,
.btn-question:focus,
.btn-question:active,
.btn-question.active,
.open .dropdown-toggle.btn-question {
  color: #FFFFFF;
  background-color: #D45B3F;
  border-color: #D45B3F;
}

.btn-question:active,
.btn-question.active,
.open .dropdown-toggle.btn-question {
  background-image: none;
}

.btn-question.disabled,
.btn-question[disabled],
fieldset[disabled] .btn-question,
.btn-question.disabled:hover,
.btn-question[disabled]:hover,
fieldset[disabled] .btn-question:hover,
.btn-question.disabled:focus,
.btn-question[disabled]:focus,
fieldset[disabled] .btn-question:focus,
.btn-question.disabled:active,
.btn-question[disabled]:active,
fieldset[disabled] .btn-question:active,
.btn-question.disabled.active,
.btn-question[disabled].active,
fieldset[disabled] .btn-question.active {
  background-color: #ED7458;
  border-color: #D45B3F;
}

.btn-question .badge {
  color: #ED7458;
  background-color: #FFFFFF;
}
